import React, { CSSProperties, ReactElement, ReactNode, useRef } from "react";
import LoadingImage from "./images/spin-gray.svg";

interface Props {
  id?: string;
  onClick?: () => void;
  className?: string;
  title?: string | ReactNode;
  disabled?: boolean;
  style?: CSSProperties;
  children?: ReactNode;
  loading?: boolean;
}

export default function Button(props: Props): ReactElement {
  const {
    id,
    onClick,
    className,
    title,
    disabled = false,
    style = {},
    children = null,
    loading = false,
    ...otherProps
  } = props;
  const buttonRef = useRef<"disabled">();
  const handleClick = () => {
    if (buttonRef.current === "disabled") {
      return;
    }
    onClick !== undefined && onClick();
    buttonRef.current = "disabled";
    setTimeout(() => {
      buttonRef.current = undefined;
    }, 1000);
  };
  return (
    <div
      id={id}
      role="button"
      tabIndex={0}
      className={`${className} ${disabled || loading ? "disabled" : ""}`}
      onClick={disabled || loading ? undefined : handleClick}
      style={Object.assign({}, style, { userSelect: "none" })}
      {...otherProps}
    >
      {loading && (
        <div style={{ position: "relative" }}>
          {children || title}
          <img
            src={LoadingImage}
            style={{
              width: 16,
              height: 16,
              position: "absolute",
              transform: "translate(-50%)",
              left: "50%",
            }}
          />
        </div>
      )}
      {!loading && <React.Fragment>{children || title}</React.Fragment>}
    </div>
  );
}
