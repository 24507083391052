import React, { ReactElement, useEffect, useState } from "react";
import Scroll from "react-scroll";
import { InteractiveHappinessAdviceResponse } from "../../models/InteractiveHappinessAdvice";
import { InteractiveHappinessAdviceChatResponse } from "../../models/InteractiveHappinessAdviceChat";
import BaseModal from "../shared/base_modal";
import Button from "../shared/button";
import Chat from "./chat_screen/chat";
import ChatInProgress from "./chat_screen/chat_in_progress";
import ChatInput from "./chat_screen/chat_input";
import ChatScreenMenuArea from "./chat_screen/chat_screen_menu_area";

interface Props {
  interactiveHappinessAdvice: InteractiveHappinessAdviceResponse;
  interactiveHappinessAdviceChats: InteractiveHappinessAdviceChatResponse[];
  lambdaFunctionUrlDomain: string;
  awsAccessRoleName: string;
}

const scroll = Scroll.animateScroll;

function scrollToBottomOfChatContent(): void {
  scroll.scrollToBottom({
    containerId: "chatContentArea",
    duration: 100,
  });
}

function getInitialInputMode(
  interactiveHappinessAdvice: InteractiveHappinessAdviceResponse,
  interactiveHappinessAdviceChats: InteractiveHappinessAdviceChatResponse[]
): "assistant" | "user" | "finished" {
  // return "user";
  // const lastChat: InteractiveHappinessAdviceChatResponse | undefined =
  //   interactiveHappinessAdviceChats.slice(-1)[0];
  let inputMode: "assistant" | "user" | "finished" = "user";
  if (interactiveHappinessAdvice.finished_at !== null) {
    inputMode = "finished";
  }
  return inputMode;
}

export default function ChatScreen(props: Props): ReactElement {
  const {
    interactiveHappinessAdvice,
    interactiveHappinessAdviceChats,
    lambdaFunctionUrlDomain,
    awsAccessRoleName,
  } = props;
  // idはダミーなので注意
  const [
    tempInteractiveHappinessAdviceChats,
    setTempInteractiveHappinessAdviceChats,
  ] = useState<InteractiveHappinessAdviceChatResponse[]>([]);
  const [messageInProgress, setMessageInProgress] = useState<string>();
  const [inputMode, setInputMode] = useState(
    getInitialInputMode(
      interactiveHappinessAdvice,
      interactiveHappinessAdviceChats
    )
  );
  const [historyUpdated, setHistoryUpdated] = useState<boolean>(false);
  const [errorModalMode, setErrorModalMode] = useState<
    | "due_to_excess_count_forced_exit_type"
    | "due_to_excess_length_forced_exit_type"
  >();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (inputMode !== "finished") {
      timeoutId = setTimeout(() => {
        scrollToBottomOfChatContent();
      }, 100);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputMode]);

  return (
    <div className="chat-screen__container">
      {errorModalMode !== undefined && (
        <BaseModal
          show={true}
          contentLabel="SuggestNewWorkModal"
          title="エラーが発生しました"
          onCloseModal={() => {
            setErrorModalMode(undefined);
          }}
        >
          <BaseModal.Body>
            <>
              {errorModalMode === "due_to_excess_count_forced_exit_type" && (
                <div className="text-center">
                  会話が想定回数を超えているため、
                  <br />
                  申し訳ございませんが初めからワークをやり直してください。
                </div>
              )}
              {errorModalMode === "due_to_excess_length_forced_exit_type" && (
                <div className="text-center">
                  大変申し訳ございません。ハッピーAIが想定外の動きをしてしまいました。
                  <br />
                  御手数ですが、再度質問入力してください。
                </div>
              )}
            </>
            <div className="d-flex justify-content-center mt-3">
              <Button
                title="画面を更新"
                className="btn button__light"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          </BaseModal.Body>
        </BaseModal>
      )}
      <ChatScreenMenuArea
        currentInteractiveHappinessAdvice={interactiveHappinessAdvice}
        historyUpdated={historyUpdated}
      />
      <div className="chat-screen__chat-container">
        <div className="chat-screen__chat-title-container">
          ハッピーAIへの相談
        </div>
        <div
          id="chatContentArea"
          className={`py-5 ${
            inputMode === "finished"
              ? "chat-screen__interactive-advice-chat-content-container chat-screen__interactive-advice-chat-content-container__finished"
              : "chat-screen__interactive-advice-chat-content-container"
          }`}
        >
          <div className="text-center">
            あなたの質問や相談に、ウェルビーイングに精通したハッピーAIが一問一答で回答します。
          </div>
          {interactiveHappinessAdviceChats.map((iwc) => (
            <Chat key={iwc.id} role={iwc.role} body={iwc.message} />
          ))}
          {tempInteractiveHappinessAdviceChats.map((iwc) => (
            <Chat key={iwc.id} role={iwc.role} body={iwc.message} />
          ))}
          <ChatInProgress
            interactiveHappinessAdvice={interactiveHappinessAdvice}
            interactiveHappinessAdviceChats={interactiveHappinessAdviceChats.concat(
              tempInteractiveHappinessAdviceChats
            )}
            userMessage={messageInProgress}
            lambdaFunctionUrlDomain={lambdaFunctionUrlDomain}
            awsAccessRoleName={awsAccessRoleName}
            onChange={() => {
              scrollToBottomOfChatContent();
            }}
            onFinish={(chats) => {
              setTempInteractiveHappinessAdviceChats(
                tempInteractiveHappinessAdviceChats.concat(chats)
              );
              setMessageInProgress(undefined);
              setInputMode("finished");
            }}
            afterRegisted={() => {
              setHistoryUpdated(true);
            }}
            onExcessLengthError={() => {
              setErrorModalMode("due_to_excess_length_forced_exit_type");
            }}
          />
        </div>
        <div className="chat-screen__chat-input-container">
          {inputMode === "finished" && (
            <div className="p-3 mb-md-4 text-center color__gray">
              {
                <div>
                  <span>あなたの幸せに貢献出来ていれば幸いです✨</span>
                  <div className="d-flex justify-content-center mt-2">
                    <Button
                      title="新規相談"
                      className="btn button__light"
                      onClick={() => {
                        window.location.href =
                          "/user/interactive_happiness_advices/new";
                      }}
                    />
                  </div>
                </div>
              }
            </div>
          )}
          {inputMode !== "finished" && (
            <ChatInput
              disabled={inputMode !== "user"}
              onSend={(msg) => {
                setMessageInProgress(msg);
                setInputMode("assistant");
              }}
              showExampleButtons={
                interactiveHappinessAdviceChats.length === 0 &&
                tempInteractiveHappinessAdviceChats.length === 0 &&
                messageInProgress === undefined
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
